<template>
    <div class="login">
        <div class="login__background background">
            <div class="background__block background__block_left"></div>
            <div class="background__block background__block_right"></div>
        </div>
        <div class="login__header"></div>
        <div class="login__container">
            <div class="login__title">
                Вход в систему
            </div>
            <div class="wrapper">
                <div class="wrapper__form">
                    <div
                        v-if="$v.$error"
                        class="error-msg">
                        {{ errorMessage }}
                    </div>
                    <input
                        v-model="username"
                        placeholder="Your JIRA/WIFI login like ivanov_i"
                        class="input"
                        :class="{
                            'input_invalid': $v.username.$error
                        }"
                    >
                    <input
                        v-model="password"
                        class="input"
                        placeholder="Password"
                        :class="{
                            'input_invalid': $v.password.$error
                        }"
                        type="password"
                    >
                    <div
                        class="button"
                        @click="tryToLogin"
                    >
                        Войти
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {required} from 'vuelidate/lib/validators';
    import {
        mapActions,
    } from 'vuex';
    
    export default {
        name: 'Login',
        data() {
            return {
                username: '',
                password: '',
                errorMessage: 'Введите логин и пароль',
            };
        },
        validations: {
            username: {
                required,
            },
            password: {
                required,
            },
        },
        methods: {
            ...mapActions('auth', {
                login: 'login',
            }),
            tryToLogin() {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    const data = {
                        sendObj: {
                            userLogin: this.username,
                            userPassword: this.password,
                        },
                        router: this.$router,
                    };
                    return this.login(data).catch(() => {
                            this.errorMessage = 'Введён некорректный логин или пароль';
                            this.password = '';
                            this.username = '';
                        })
                }
            },
        },
    };
</script>

<style
    scoped
    lang="scss"
>
    @import "src/styles/colors";
    
    .login {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
        &__header {
            width: 100%;
            height: 92px;
            background-image: url("../assets/logo_dark.svg");
            background-position: center;
            background-repeat: no-repeat;
            position: sticky;
            top: 0;
            box-shadow: 0 4px 28px rgba(50, 63, 86, 0.11);
            z-index: 1;
            background-color: $white;
        }
        
        &__container {
            display: flex;
            flex-direction: column;
            height: calc(100% - 92px);
            z-index: 1;
            justify-content: center;
        }
        &__title {
            color: $greyDark;
            font-weight: 700;
            font-size: 28px;
            line-height: 26px;
            margin-bottom: 40px;
        }
        .background {
            width: 100vw;
            height: 100vh;
            position: absolute;
            top: 0;
            left: 0;
            overflow: hidden;
            filter: blur(300px);
            &__block {
                position: absolute;
                height: 678px;
                width: 678px;
                &_left {
                    background-color: #A0DB9C50;
                    bottom: -100px;
                    left: -50px;
                }
                &_right {
                    top: 31px;
                    right: -100px;
                    background-color: #6461FF50;
                }
            }
        }
    }
    
    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 504px;
        background-color: hsla(0,0%,100%,.302);
        border-radius: 20px;
        height: 344px;
        &__form {
            display: grid;
            grid-template-rows: 56px 56px 56px;
            grid-row-gap: 24px;
            background: #fff;
            width: calc(440px - 32px * 2);
            height: calc(280px - 32px * 2);
            border-radius: 10px;
            position: relative;
            padding: 32px;
            .error-msg {
                color: $red;
                position: absolute;
                top: 8px;
                left: 32px;
            }
        }
    }
    
    .input {
        font-family: 'Nunito', sans-serif;
        display: block;
        font-size: 18px;
        width: available;
        height: 52px;
        padding-left: 15px;
        font-weight: 600;
        color: $greyDark;
        border: 1px solid $greyLite;
        border-radius: 4px;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        outline: none;
        &::placeholder {
            font-weight: 600;
            font-family: 'Nunito', sans-serif;
            color: $greyMid;
        }
        &_invalid {
            border: 1px solid $red;
        }
    }
    
    .button {
        width: available;
        height: 56px;
        background-color: $green;
        color: $white;
        cursor: pointer;
        border-radius: 4px;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s;
        
        &:hover {
            box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
        }
    }
</style>
